<template>
  <div class="flex flex-grow mx-2 active:transform active:scale-97">
    <NuxtLink
      to="legg-til-kalender"
      class="flex flex-grow justify-center p-2 text-green-600 hover:text-green-500 hover:border-1 hover:border-green-500 hover:-my-1px bg-white dark:bg-black rounded-lg shadow-lg"
    >
      {{ I18nNO.importCalendar }}
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import PlainI18nNO from "@/data/I18nNO";
const I18nNO = reactive(PlainI18nNO);
</script>
