<template>
  <section class="text-blue-gray-800">
    <div class="mx-2 mt-3 mb-2 rounded-lg">
      <figure class="relative flex flex-col justify-center">
        <img
          class="mb-3 object-cover w-full h-28 sm:h-64 rounded-lg"
          :src="Location.img"
          :alt="Location.imgAlt"
        />
        <figcaption
          class="absolute bottom-0 px-3 pb-1 mx-3 self-center text-center whitespace-normal text-2xl sm:text-4xl border border-white text-white bg-dark-500 bg-opacity-50 backdrop-filter backdrop-blur-sm rounded-lg"
        >
          {{ I18nNO.intro }}
        </figcaption>
      </figure>
    </div>
    <ClientOnly>
      <TimeLine :events="events" />
      <template #fallback>
        <TimeLinePlaceholder />
      </template>
    </ClientOnly>
    <div class="flex mb-4 print:hidden">
      <ImportButton />
      <ProgramButton />
    </div>
  </section>
</template>

<script setup lang="ts">
import PlainI18nNO from "@/data/I18nNO";
const I18nNO = reactive(PlainI18nNO);
import Location from "@/data/Location";
import getCalendarEvents from "@/data/CalendarEvents";

const now = new Date();
const fourHoursAgo = new Date().setHours(now.getHours() - 4);
const in12Days = new Date().setDate(now.getDate() + 12);
const events = reactive(
  (await getCalendarEvents(useRuntimeConfig().public.apiKey)).filter(
    (event) =>
      Date.parse(event.timestamp) > fourHoursAgo && // Date.parse(): millis UTC
      Date.parse(event.timestamp) < in12Days
  )
);

onMounted(() => window.scrollTo(0, 0));
</script>
